import React from "react"
import { Link } from "gatsby"

import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinVijdame from "./../images/articles-lutein-vijdame.jpg";
import HepatoJluchka from "./../images/articles-hepato-jluchka.jpg";
import LuteinSpasim from "./../images/articles-lutein-spasim-zrenieto.jpg";
import HepatoZasilvat from "./../images/articles-hepato-zasilvat-organ.jpg";
import HepatoGrigorov from "./../images/articles-hepato-grigorov.jpg";
import LuteinMerki from "./../images/articles-lutein-kruti-merki.jpg"
import LuteinRestart from "./../images/articles-lutein-restartirat.jpg"
import LuteinHowTo from "./../images/articles-lutein-howto.jpg"
import HepatoCherenDrob from "./../images/articles-hepato-cheren-drob.jpg"
import LuteinFirstAid from "./../images/articles-lutein-first-aid.jpg"
import LuteinVirusi from "./../images/articles-lutein-virusi.jpg"

import LuteinSpecialisti from "./../images/articles-lutein-specialisti.jpg"
import LuteinSiniaSvetlina from "./../images/articles-lutein-sinia-svetlina.jpg"
import Lutein5Reshenia from "./../images/article-5-reshenia.jpg"
import LuteinPrevantivno from "./../images/articles-lutein-prevantivno.jpg"
import HepatoRisk from "./../images/articles-hepato-da-namalim-riska.jpg"

import StavienStava from "./../images/articles-stavien-sinovialna-stava.jpg"
import PsoriazalSarsaparila from "./../images/articles-psoriazal-sarsaparila-i-psoriazis.jpg"
import LuteinNoshtno from "./../images/article-lutein-noshtno-vijdane.jpg"
import HepatofelinToksini from "./../images/articles-hepatofelin-toksini.jpg"
import HepatofelinSilimarin from "./../images/articles-hepato-silimarin.jpg"
import PsoriazalSpravim from "./../images/articles-psoriazal-plus-kak-da-se-spravim.jpg"

const ArticleListPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "articles" }}>
    <Seo title="Здравни статии" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>СТАТИИ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-4 ">
        <Col sm={12} className="">
          <h1 className="display-5 font-light large-heading-mobile">Статии</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h5 className="font-light">
            Тук ще откриете статии на различни здравни тематики. Статиите са
            разработени от медицински специалисти или с тяхно съдействие.
          </h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12}>
          <Link
            to="/articles/kak-da-vijdame-po-dobre/"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinVijdame}
                alt="Как да виждаме по-добре?"
              />
              <Card.Body>
                <h4 className="font-light">Как да виждаме по-добре?</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Специална разработка помага и в най-заплетените случаи
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link
            to="/articles/inovativen-podhod-ukrotiava-jluchkata/"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatoJluchka}
                alt="Иновативен подход укротява жлъчката?"
              />
              <Card.Body>
                <h4 className="font-light">
                  Иновативен подход укротява жлъчката
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Работи безотказно, твърдят специалисти
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link
            to="/articles/kak-da-si-spasim-zrenieto/"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinSpasim}
                alt="Как да си спасим зрението?"
              />
              <Card.Body>
                <h4 className="font-light">Как да си спасим зрението?</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Активен „еликсир“ за очи подсигурява всичко необходимо
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link
            to="/articles/zasilvat-organ-koito-ni-pazi"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatoZasilvat}
                alt="Засилват орган, който ни пази"
              />
              <Card.Body>
                <h4 className="font-light">Засилват орган, който ни пази</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Прочистваме „филтъра“ ни против отрови и вируси
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link
            to="/articles/reshenie-za-uvreden-cheren-drob"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatoGrigorov}
                alt="Имаме решение за увредения от вируси и антибиотици черен дроб"
              />
              <Card.Body>
                <h4 className="font-light">
                  Имаме решение за увредения от вируси и антибиотици черен дроб
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Интервю с проф. д-р Никола Григоров – „Пирогов“, София
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles/kruti-merki" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinMerki}
                alt="Крути мерки срещу отслабеното зрение"
              />
              <Card.Body>
                <h4 className="font-light">
                  Крути мерки срещу отслабеното зрение
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Да спреш да виждаш е страшно. Разбираме, че сме лишени от
                  нормален живот, чак когато „завесата“ падне пред очите ни
                  безвъзвратно. После ни е яд, че не сме взели мерки по-рано.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles/kak-se-pravi" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinHowTo}
                alt="Как се прави здраво око?"
              />
              <Card.Body>
                <h4 className="font-light">Как се прави здраво око?</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Отслабеното зрение не е просто досадно неудобство. В
                  по-напреднала фаза то се превръща в истински ад, който ни
                  изолира от света, докато накрая не заживеем съвсем самотни.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles/restartirat-zrenieto" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinRestart}
                alt="Добра новина: Рестартират зрението"
              />
              <Card.Body>
                <h4 className="font-light">
                  Добра новина: Рестартират зрението
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Своеобразен рестарт на зрението е последната добра новина,
                  поднесена ни от медицинските среди.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles/cherniat-drob-ne-boli" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatoCherenDrob}
                alt="Черният дроб не боли — той се срива изведнъж"
              />
              <Card.Body>
                <h4 className="font-light">
                  Черният дроб не боли - той се срива изведнъж
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Пътят от завишените чернодробни ензими до тежкия проблем е
                  кратък
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link to="/articles/purva-pomosht" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinFirstAid}
                alt="Първа помощ за очите"
              />
              <Card.Body>
                <h4 className="font-light">Първа помощ за очите</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Ефективната грижа за зрението се крие в природата
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/virusite-ni-atakuvat-prez-ochite"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinVirusi}
                alt="Вирусите ни атакуват и през очите"
              />
              <Card.Body>
                <h4 className="font-light">
                  Вирусите ни атакуват и през очите
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Адекватната грижа за зрителните ни органи може да предотврати
                  инвазията
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          {/* <Link
            to="/articles/kakvo-e-hialuronova-kiselina"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HyaluronicImg}
                alt="Какво е хиалуронова киселина?"
              />
              <Card.Body>
                <h4 className="font-light">Какво е хиалуронова киселина?</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Хиалуроновата киселина е във всички нас още от момента на
                  раждане, но количеството й в организма намалява с възрастта и
                  допринася към процеса на стареене
                </Card.Text>
              </Card.Body>
            </Card>
          </Link> */}
          <Link
            to="/articles/specialistite-kak-da-vijdame-po-dobre"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinSpecialisti}
                alt="Какво е хиалуронова киселина?"
              />
              <Card.Body>
                <h4 className="font-light">
                  Специалистите: Как да виждаме по-добре?
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Лекари от цялата страна отдавна са открили един ефективен
                  подход
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/efekta-na-siniata-svetlina"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinSiniaSvetlina}
                alt="Ефекта на синята светлина върху очите"
              />
              <Card.Body>
                <h4 className="font-light">
                  Ефекта на синята светлина върху очите
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Всички знаем за вредите на ултравиолетовите лъчи от слънцето
                  върху кожата ни
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/5-1-reshenia-za-silni-ochi"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={Lutein5Reshenia}
                alt="5 решения за силни очи"
              />
              <Card.Body>
                <h4 className="font-light">5 решения за силни очи</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Продължителната работа пред компютъра води до неприятни
                  последици
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/prevantivno-ochno-zdrave"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinPrevantivno}
                alt="Лутеин, зеаксантин и черна боровинка за превантивно очно
                  здраве"
              />
              <Card.Body>
                <h4 className="font-light">
                  Лутеин, зеаксантин и черна боровинка за превантивно очно
                  здраве
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Преди да обсъждаме лутеинa, зеаксантинa и черната боровинка,
                  трябва да имаме основно разбиране какво е макулната
                  дегенерация
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link to="/articles/kak-da-namalim-riska" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatoRisk}
                alt="Как да намалим риска от чернодробни заболявания?"
              />
              <Card.Body>
                <h4 className="font-light">
                  Как да намалим риска от чернодробни заболявания?
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Чернодробните заболявания се „отключват” от много и различни
                  причини ̶ болести и инфекции
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles/ustroistvo-na-stavi" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={StavienStava}
                alt="Какво е устройството на свободно-подвижните стави
                    (синовиални стави)?"
              />
              <Card.Body>
                <h4 className="font-light">
                  Какво е устройството на свободно-подвижните стави (синовиални
                  стави)?
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Мястото, където две повърхности на костите са в
                  съприкосновение и осъществяват движение една спрямо друга
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/sarsaparilata-i-psoriazisa"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={PsoriazalSarsaparila}
                alt="Сарсапарилата и псориазиса"
              />
              <Card.Body>
                <h4 className="font-light">Сарсапарилата и псориазиса</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Основната съставка на Псориазал Плюс е корен от сарсапарила.
                  Това е билка, използвана от векове за лечение на кожата и
                  пречистване на кръвта
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link to="/articles/lutein-noshtno-vijdane" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={LuteinNoshtno}
                alt="Изследване: Лутеинът би могъл да подобри нощното виждане"
              />
              <Card.Body>
                <h4 className="font-light">
                  Изследване: Лутеинът би могъл да подобри нощното виждане
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Дневна доза лутеин би могла да подобри зрението и да помогне
                  при шофиране през нощта
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link to="/articles/toksinite-opasen-vrag" className="link-no-style">
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatofelinToksini}
                alt="Токсините - опасен враг за организма"
              />
              <Card.Body>
                <h4 className="font-light">
                  Токсините - опасен враг за организма
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Много често, без да подозираме това, страдаме от различни
                  болести, които са следствие от натрупването на токсини в
                  организма
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/silimarin-silen-antioksidant-i-hepatoprotektor"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={HepatofelinSilimarin}
                alt="Силимарин: силен антиоксидант и хепатопротектор"
              />
              <Card.Body>
                <h4 className="font-light">
                  Силимарин: силен антиоксидант и хепатопротектор
                </h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Силимарина е уникален флавоноиден комплекс, съдържащ силибин,
                  силидианин и силихрисин, извличан от растението бял трън
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>

          <Link
            to="/articles/kak-da-se-spravim-s-psoriazisa"
            className="link-no-style"
          >
            <Card className="d-flex flex-row article-list">
              <Card.Img
                className="rounded-left"
                src={PsoriazalSpravim}
                alt="Как да се справим с псориазиса?"
              />
              <Card.Body>
                <h4 className="font-light">Как да се справим с псориазиса?</h4>
                <Card.Text className="font-light d-none d-sm-block">
                  Псориазисът е кожно заболяване, чието наименование произхожда
                  от гръцката дума "псора", означаваща лющене
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default ArticleListPage
